import { Link } from "gatsby"
import React from "react"
import Navigation from "../navigation/index"

import imgLogo from "../../images/logo.svg"
import "./_header.scss"

const Header = () => (
  <header role="banner" className="_8iqo2b">
    <div className="container">
      <Link className="logo" to="/"><img src={imgLogo} alt="Logo das kleine röstwerk"/></Link>
      <Navigation />
    </div>
  </header>
)

export default Header
