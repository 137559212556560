import React from "react"
import { Link } from "gatsby"

import imgFeinschecker2018 from "../../images/feinschmecker.jpg"
import imgFeinschecker2022 from "../../images/feinschmecker2022.jpg"

import "./_footer.scss"

const Footer = () => (
  <footer>
    <div className="container footer">
      <div className="footernav">
        <div className="footernav-left">
          <p>das kleine röstwerk</p>
          <p>
            wir verstehen hochwertigen kaffee als handwerkliches produkt.
            bereits die herkunft der bohnen, die boden- und klimaverhältnisse,
            die höhenlagen und die aufbereitung auf den plantagen bestimmen den
            jeweiligen kaffeecharakter. wir verwenden ausgesuchte kaffeebohnen
            aus den besten anbaugebieten der ganzen welt. erst durch die
            langsame und individuelle röstung per hand entfalten sich die
            einzigartigen geschmacksaromen und -nuancen unserer ausgezeichneten
            kaffees, die besonders bekömmlich sind. mit viel erfahrung und
            feingefühl werden bei uns leckere kaffees kreiert.
          </p>
        </div>
        <div className="footernav-center">
          <p>
            das kleine röstwerk
            <br />
            andreasplatz 20
            <br />
            31134 hildesheim
            <br />
            info@daskleineroestwerk.de
          </p>
          <p>
            tischreservierung <br />
            telefon 05121 98910-40 <br />
            oder direkt bei uns
            <br />
            in der kaffeebar
            <br />
            {"("}online nicht möglich{")"}
          </p>
          <div style={{ display: `flex`, padding:0 }}>
            <img
              src={imgFeinschecker2018}
              alt="Feinschmecker 2018"
              height="200"
              style={{ marginRight:`15px`}}            
            />
            <img
              src={imgFeinschecker2022}
              alt="Feinschmecker 2022"
              height="200"
            />
          </div>
        </div>
        <div className="footernav-right">
          <Link to="/impressum">impressum</Link>
          <Link to="/contact">kontakt</Link>
          <Link to="/agbs">agbs</Link>
          <Link to="/datenschutz">datenschutz</Link>
        </div>
      </div>
      <div className="copyright">
        © {new Date().getFullYear()},{` `}
        <a href="https://www.daskleineroestwerk.de">das kleine röstwerk</a>
      </div>
    </div>
  </footer>
)

export default Footer
