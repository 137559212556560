import { Link } from "gatsby"
import React from "react"
import "./_navigation.scss"

const Navigation = () => (
  <div className="topnav" id="topnav">
    <nav className="main-nav">
      <div className="dropdown">
        <div className="dropdown-toggle">
          <div className="dropdown-icon-toggle"></div>
          <Link className="main-nav-link" to="/">das kleine röstwerk</Link>
        </div>
        <nav className="dropdown-list">
          <Link className="dropdown-link" to="/roesterei">
            rösterei
          </Link>
          <Link className="dropdown-link" to="/kaffeebar">
            kaffeebar
          </Link>
          <Link className="dropdown-link" to="/laden">
            laden
          </Link>
          <Link className="dropdown-link" to="/geschaeftskunden">
            geschäftskunden
          </Link>
        </nav>
      </div>
      <a className="main-nav-link" href="https://shop.daskleineroestwerk.de">onlineshop</a>
      <Link className="main-nav-link" to="/contact">kontakt</Link>
    </nav>
  </div>
)

export default Navigation
